import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import { useEffect } from "react";
import { SWRConfig } from "swr";
import AppStoreContextProvider from "../contexts/StoreContextProvider/StoreContextProvider";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import "../styles/globals.css";
import "../styles/heroicons.css";
import "../styles/keyframes.scss";
import { detectIOS, promptUser } from "./../helpers/promptUser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "next/router";
import ToastifyComponent from "../components/ToastifyComponent/ToastifyComponent";
import MaintenancePage from "../components/primaryrender/maintenance/Maintenance";
import Router from "next/router";

const stripePromise = loadStripe(`${process.env.NEXT_PUBLIC_STRIPE_KEY}`);

let deferredPrompt;
export function addToHomeScreen() {
  const isIOS = detectIOS();
  if (isIOS) {
    window.open(
      "https://help.toplinepro.com/hc/en-us/articles/4417310943515-How-to-Add-the-ProPhone-Dashboard-to-Your-Phone-like-an-App",
      "_blank"
    );
  } else {
    promptUser(deferredPrompt);
  }
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: any) {
  if (process.env.NEXT_PUBLIC_MAINTENANCE === "true") {
    return <MaintenancePage />;
  }

  const [googleClientId, setGoogleClientId] = useState<string>("");
  const router = useRouter();

  useEffect(() => {
    if (router.asPath === "/checkout") {
      router.push("/login");
    }
  }, []);

  useEffect(() => {
    if (
      "serviceWorker" in navigator &&
      process.env.NEXT_PUBLIC_ENABLE_CUSTOM_SERVICE_WORKER === "true"
    ) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            console.log(
              "Service Worker registered with scope: ",
              registration.scope
            );

            registration.addEventListener("updatefound", () => {
              const installingWorker = registration.installing;
              if (installingWorker == null) {
                return;
              }
              installingWorker.addEventListener("statechange", () => {
                if (installingWorker.state === "installed") {
                  if (navigator.serviceWorker.controller) {
                    router.reload();
                  }
                }
              });
            });

            const handleRouteChange = async () => {
              await registration.update();
            };

            // Listen for route changes and check for updates
            Router.events.on("routeChangeComplete", handleRouteChange);

            // Clean up event listener on unmount
            return () => {
              Router.events.off("routeChangeComplete", handleRouteChange);
            };
          })
          .catch((err) => {
            console.log("Service Worker registration failed:", err);
          });
      });
    }
  }, []);

  useEffect(() => {
    console.log("beforeinstallprompt");
    window.addEventListener("beforeinstallprompt", function (e) {
      e.preventDefault();
      deferredPrompt = e;
      console.log("deferredPrompt", deferredPrompt);
    });
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GMB_CLIENT_ID) {
      setGoogleClientId(process.env.NEXT_PUBLIC_GMB_CLIENT_ID);
    }
  }, [process.env.NEXT_PUBLIC_GMB_CLIENT_ID]);

  const getLayout =
    Component?.getLayout || ((page: any) => <EmptyLayout>{page}</EmptyLayout>);

  return (
    <SessionProvider session={session}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <SWRConfig value={{}}>
          <AppStoreContextProvider
            googleClientId={googleClientId}
            setGoogleClientId={setGoogleClientId}
          >
            {getLayout(
              <Elements stripe={stripePromise}>
                <Component {...pageProps} />
              </Elements>
            )}
            {process.env.NEXT_PUBLIC_ENVIRONMENT === "development" && (
              <Script
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                  __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/xrfjoa7s';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})(); window.IntercomSettings = {app_id: 'xrfjoa7s',hide_default_launcher: true,custom_launcher_selector:'#intercom_launcher'};`,
                }}
              />
            )}
            <ToastifyComponent />
          </AppStoreContextProvider>
        </SWRConfig>
      </GoogleOAuthProvider>
    </SessionProvider>
  );
}
export default MyApp;
